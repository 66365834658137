@tailwind base;
@tailwind components;
@tailwind utilities;

.element {
  @apply p-2 w-full font-light border-2;
}

/* Helium to the top right  */
.element-2 {
  @apply col-start-[-2];
}

/* Boron & Alumunium to the right  */
.element-5,
.element-13 {
  @apply col-start-13;
}

/* Lanthinide & Actinoid split  */
.element-58,
.element-90 {
  @apply col-start-3;
}

.noble {
  @apply bg-[#8488c4] border-[#8488c4];
}

/* Alkali Metal  */
.alkali {
  @apply bg-[#a2b242] border-[#a2b242];
}

/* Alkaline Earth Metal  */
.alkaline {
  @apply bg-[#70c097] border-[#70c097];
}

/* Transition Metal  */
.transition {
  @apply bg-[#81c4fb] border-[#81c4fb];
}

/* Polyatomic Non Metal  */
.polyatomic {
  @apply bg-[#84949c] border-[#84949c];
}

/* Diatomic Non Metal  */
.diatomic {
  @apply bg-[#b99753] border-[#b99753];
}

/* Post Transition Metal  */
.post-transition {
  @apply bg-[#a9a9a9] border-[#a9a9a9];
}

/* Metalloid  */
.metalloid {
  @apply bg-[#d3635d] border-[#d3635d];
}

.lanthanide {
  @apply bg-[#ffe634] border-[#ffe634];
}

/* Actinide  */
.actinide {
  @apply bg-[#fcb52f] border-[#fcb52f];
}

/* The Unknown Element  */
.unknown {
  @apply bg-[#cff] border-[#cff];
}

.element-57 {
  @apply mt-0;
}

.active-element {
  @apply brightness-75;
}

.disabled {
  @apply opacity-40 hover:cursor-default;
}

.element-box {
  @apply col-[3/5] row-[2/4] px-1 flex flex-col items-center justify-center gap-2 font-light;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* starting position of sidebar */
  width: 300px;
  height: 100vh;
  overflow-x: hidden; /* prevents overflow */
  background: #fff;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

.sidebar.open {
  right: 0; /* position when sidebar is open */
}

.shrink {
  width: calc(100% - 300px); /* or whatever width you want for the sidebar */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.icon-white path {
  stroke: #fff;
}
